import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import EditableList from '../components/editableList';
import FooterButton from '../components/footerButton';
import Layout from '../components/layout';
import LoadingWrapper from '../components/loadingWrapper';
import SelectButtonGroup from '../components/selectButtonGroup';
import TextInput from '../components/textInput';
import useAdministrateConfigForm, {
  AdminActionType,
  AdministrateConfigForm,
  ExternalState,
} from '../hooks/useAdministrateConfigForm';
import { getServiceTypes } from '../services/formConfiguration';
import {
  Configuration,
  FORM_TYPE_FIELDS,
  FORM_TYPE_FIELD_LABELS,
  FORM_TYPE_NAME,
  FormConfiguration,
  FormType,
  FormTypeValues,
} from '../services/lists';

const useStyles = makeStyles({
  formContainer: {
    width: '100%',
  },
  comboBox: {
    '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child': {
      height: '2.5rem',
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
    },
  },
});

const AdminActionTypeTitle: Record<AdminActionType, string> = {
  [AdminActionType.UpdateLocation]: 'Update Location',
  [AdminActionType.CloneLocation]: 'Clone Location',
  [AdminActionType.EnableServiceTypes]: 'Enable/Disable Service Types',
};
const AllActionActionTypes = Object.values(AdminActionType);
const AllActionActionTypesTitles = AllActionActionTypes.map(
  t => AdminActionTypeTitle[t]
);

const AllFormFields = Object.keys(
  FORM_TYPE_FIELD_LABELS
) as (keyof FormConfiguration)[];

export const UpdateLocationConfiguration: React.FC<{
  parentForm: Required<ExternalState>;
  setApiRequest: AdministrateConfigForm['setApiRequest'];
  clearApiRequest: AdministrateConfigForm['clearApiRequest'];
}> = ({
  parentForm: { config: initialConfig, location },
  setApiRequest,
  clearApiRequest,
}) => {
  const classes = useStyles();

  const [config, setConfig] = useState<Configuration>(initialConfig);
  const [formType, setFormType] = useState<FormType>();
  const [list, setList] = useState<keyof FormConfiguration>();

  const [formTypeLabel, setFormTypeLabel] = useState('');
  const [listLabel, setListLabel] = useState('');

  useEffect(() => {
    setList(undefined);
    setListLabel('');
  }, [location, formType]);

  useEffect(() => {
    clearApiRequest();
  }, [location, formType, list]);

  const updateState = (updatedConfiguration: Configuration): void => {
    setConfig({ ...updatedConfiguration });
    setApiRequest(AdminActionType.UpdateLocation, {
      ...updatedConfiguration,
    });
  };

  return (
    <>
      <Autocomplete
        aria-label={'Form'}
        options={FormTypeValues.filter(t => t !== FormType.Home).map(
          t => FORM_TYPE_NAME[t]
        )}
        onChange={(_: any, newValue: string): void => {
          const selectedFormType = FormTypeValues.find(
            t => FORM_TYPE_NAME[t] === newValue
          );
          setFormType(selectedFormType);
        }}
        inputValue={formTypeLabel}
        onInputChange={(_event: any, value: string): void => {
          setFormTypeLabel(value);
        }}
        renderInput={(params: any): JSX.Element => (
          <TextField
            {...params}
            label="Form"
            margin="normal"
            className={classes.comboBox}
            InputLabelProps={{ shrink: false }}
          />
        )}
      />
      {formType && (
        <Autocomplete
          aria-label={'List'}
          options={FORM_TYPE_FIELDS[formType].map(
            k => FORM_TYPE_FIELD_LABELS[k]
          )}
          onChange={(_: any, newLabel: string): void => {
            const key = AllFormFields.find(
              k => FORM_TYPE_FIELD_LABELS[k] === newLabel
            );
            setList(key);
          }}
          inputValue={listLabel}
          onInputChange={(_: any, newLabel: string): void => {
            setListLabel(newLabel);
          }}
          renderInput={(params: any): JSX.Element => (
            <TextField
              {...params}
              label="List"
              margin="normal"
              className={classes.comboBox}
              InputLabelProps={{
                shrink: false,
              }}
            />
          )}
        />
      )}
      {formType && list && config.items[formType][list] && (
        <>
          <FormControlLabel
            control={
              <Checkbox
                id="allow-other"
                aria-label={'Allow "Other"'}
                checked={config.items[formType][list]?.includes('Other')}
                onChange={(e, checked): void => {
                  if (
                    checked &&
                    config.items[formType][list]?.includes('Other')
                  ) {
                    return;
                  } else if (
                    !checked &&
                    !config.items[formType][list]?.includes('Other')
                  ) {
                    return;
                  } else if (checked) {
                    config.items[formType][list]?.push('Other');
                    updateState(config);
                  } else {
                    config.items[formType][list] = config.items[formType][
                      list
                    ]?.filter(x => x !== 'Other');
                    updateState(config);
                  }
                }}
              />
            }
            label="Allow Other"
          ></FormControlLabel>
          <EditableList
            label={FORM_TYPE_FIELD_LABELS[list]}
            listKey={`${location}-${formType}-${FORM_TYPE_FIELD_LABELS[list]}`}
            list={config.items[formType][list] || []}
            updateList={(newList): void => {
              config.items[formType][list] = newList;
              updateState(config);
            }}
          />
        </>
      )}
    </>
  );
};

const EnableDisableServiceTypes: React.FC<{
  parentForm: Required<ExternalState>;
  setApiRequest: AdministrateConfigForm['setApiRequest'];
}> = ({ parentForm: { config: initialConfig }, setApiRequest }) => {
  const initialServiceTypes = getServiceTypes(initialConfig);
  const [serviceTypes, setServiceTypes] = useState(initialServiceTypes);

  const updateState = (updatedServiceTypes: FormType[]): void => {
    const apiRequest = { ...initialConfig };
    apiRequest.items[FormType.Home].services = updatedServiceTypes;
    setApiRequest(AdminActionType.EnableServiceTypes, apiRequest);

    setServiceTypes(updatedServiceTypes);
  };

  return (
    <SelectButtonGroup
      id="services-list"
      multi
      maxDisplayed={FormTypeValues.length}
      label="Services"
      options={FormTypeValues.filter(t => t !== FormType.Home).map(
        t => FORM_TYPE_NAME[t]
      )}
      value={serviceTypes.map(t => FORM_TYPE_NAME[t])}
      onChange={(newServiceTypes): void => {
        if (!Array.isArray(newServiceTypes)) return;
        const selectedServiceTypes = FormTypeValues.filter(t =>
          newServiceTypes.some(x => FORM_TYPE_NAME[t] === x)
        );
        updateState(selectedServiceTypes);
      }}
    />
  );
};

const CloneLocationConfiguration: React.FC<{
  parentForm: Required<ExternalState>;
  setApiRequest: AdministrateConfigForm['setApiRequest'];
}> = ({ parentForm: { config: initialConfig }, setApiRequest }) => {
  const [destinationLocation, setDestinationLocation] = useState<string>('');

  const updateState = (newLocationName: string): void => {
    setDestinationLocation(newLocationName);
    setApiRequest(AdminActionType.CloneLocation, {
      sourceLocationName: initialConfig.name,
      destinationLocationName: newLocationName,
    });
  };

  return (
    <TextInput
      label="New Location"
      id="new-location"
      name="new-location"
      value={destinationLocation}
      placeholder="Name"
      onChange={(e: any): void => {
        updateState(e.target.value);
      }}
    />
  );
};

export const PureAdministrateConfig: React.FC<AdministrateConfigForm> = ({
  status: { loading, error, submitted, submitting },
  canDisplaySubForm,
  canSubmit,
  formState,
  chooseAdminActionType,
  chooseLocation,
  setApiRequest,
  clearApiRequest,
  submit,
}) => {
  const classes = useStyles();

  const renderAdminActionSubForm = (): JSX.Element => {
    if (!canDisplaySubForm) {
      return <CircularProgress />;
    }

    switch (formState.adminActionType) {
      case AdminActionType.UpdateLocation:
        return (
          <UpdateLocationConfiguration
            parentForm={{ ...(formState as Required<ExternalState>) }}
            setApiRequest={setApiRequest}
            clearApiRequest={clearApiRequest}
          />
        );
      case AdminActionType.EnableServiceTypes:
        return (
          <EnableDisableServiceTypes
            parentForm={{ ...(formState as Required<ExternalState>) }}
            setApiRequest={setApiRequest}
          />
        );
      case AdminActionType.CloneLocation:
        return (
          <CloneLocationConfiguration
            parentForm={{ ...(formState as Required<ExternalState>) }}
            setApiRequest={setApiRequest}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Layout title="Administrate configuration">
      <LoadingWrapper
        error={error}
        loading={loading}
        submitting={submitting}
        hasConfig={true}
        submitted={submitted}
      >
        <form
          className={classes.formContainer}
          autoComplete="off"
          onSubmit={(e): void => {
            e.preventDefault();
            submit();
          }}
        >
          {formState.locations && (
            <SelectButtonGroup
              id="admin-action"
              label="Action"
              value={
                formState.adminActionType
                  ? AdminActionTypeTitle[formState.adminActionType]
                  : undefined
              }
              options={AllActionActionTypesTitles}
              onChange={(value): void => {
                const selectedAction = AllActionActionTypes.find(
                  t => AdminActionTypeTitle[t] === value
                );
                if (!!selectedAction) {
                  chooseAdminActionType(selectedAction);
                }
              }}
            />
          )}

          {formState.adminActionType && (
            <Autocomplete
              aria-label={'Location'}
              options={formState.locations}
              value={formState.location || null}
              onChange={(_: any, selectedLocation: string): void => {
                chooseLocation(selectedLocation);
              }}
              renderInput={(params: any): JSX.Element => (
                <TextField
                  {...params}
                  label="Location"
                  className={classes.comboBox}
                  margin="normal"
                  InputLabelProps={{ shrink: false }}
                />
              )}
            />
          )}

          {formState.location && renderAdminActionSubForm()}

          <FooterButton type="submit" disabled={!canSubmit || submitting}>
            {submitting ? (
              <>
                <CircularProgress />
                <Typography variant="srOnly">SUBMITTING</Typography>
              </>
            ) : (
              'SUBMIT'
            )}
          </FooterButton>
        </form>
      </LoadingWrapper>
    </Layout>
  );
};

export const AdministrateConfig: React.FC = () => {
  const {
    status,
    canDisplaySubForm,
    canSubmit,
    formState,
    chooseAdminActionType,
    chooseLocation,
    setApiRequest,
    clearApiRequest,
    submit,
  } = useAdministrateConfigForm();

  return (
    <PureAdministrateConfig
      status={status}
      canDisplaySubForm={canDisplaySubForm}
      canSubmit={canSubmit}
      formState={formState}
      chooseAdminActionType={chooseAdminActionType}
      chooseLocation={chooseLocation}
      setApiRequest={setApiRequest}
      clearApiRequest={clearApiRequest}
      submit={submit}
    />
  );
};

export default AdministrateConfig;
