import { TextField } from '@material-ui/core';
import React from 'react';
import Button from './button';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import RemoveIcon from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/styles';
import { BLUE, NAVY_BLUE } from '../theme';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gridTemplateColumns:
      '[up] auto [text] 1fr [down] auto [space] 1rem [remove] auto [end]',
    marginBottom: '1rem',
  },
  value: {
    backgroundColor: BLUE,
    flexDirection: 'unset',
    '& .MuiInput-underline': {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      width: '100%',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff8',
      borderBottomWidth: '2px',
    },
  },
  moveUp: {
    borderRadius: '3px 0px 0px 3px',
    borderRight: `1px solid ${NAVY_BLUE}`,
  },
  moveDown: {
    borderRadius: '0px 3px 3px 0px',
    borderLeft: `1px solid ${NAVY_BLUE}`,
  },
}));

export interface EditableListOptionProps {
  moveUp: () => void;
  moveDown: () => void;
  remove: () => void;
  onChange: (newValue: string) => void;
  value: string;
}

export const EditableListOption: React.FC<EditableListOptionProps> = ({
  moveUp,
  moveDown,
  onChange,
  remove,
  value,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Button className={classes.moveUp} size="small" onClick={moveUp}>
        <KeyboardArrowUpIcon fontSize="small" />
      </Button>
      <TextField
        className={classes.value}
        type="text"
        onChange={(event): void => onChange(event.target.value)}
        value={value}
        hiddenLabel
        margin="none"
      />
      <Button className={classes.moveDown} size="small" onClick={moveDown}>
        <KeyboardArrowDownIcon fontSize="small" />
      </Button>
      <div>{/* Spacer */}</div>
      <Button size="small" onClick={remove}>
        <RemoveIcon fontSize="small" />
      </Button>
    </div>
  );
};

export default EditableListOption;
