import { makeStyles } from '@material-ui/styles';
import React from 'react';
import Button from './button';
import AddIcon from '@material-ui/icons/Add';
import { TextField } from '@material-ui/core';
import { BLUE } from '../theme';

const useStyles = makeStyles({
  addContainer: {
    display: 'grid',
    gridTemplateColumns: '[text] 1fr [space] 1rem [remove] auto [end]',
    marginBottom: '1rem',
  },
  addListOptionValue: {
    backgroundColor: BLUE,
    flexDirection: 'unset',
    '& .MuiInput-underline': {
      paddingLeft: '0.5rem',
      paddingRight: '0.5rem',
      width: '100%',
    },
    '& .MuiInput-underline:before': {
      borderBottomColor: '#fff8',
      borderBottomWidth: '2px',
    },
  },
});

export interface AddListItemProps {
  value: string;
  onChange: (newValue: string) => void;
  add: () => void;
}

export const AddListItem: React.FC<AddListItemProps> = ({
  value,
  onChange,
  add,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.addContainer}>
      <TextField
        className={classes.addListOptionValue}
        type="text"
        onChange={(event): void => onChange(event.target.value)}
        value={value}
        hiddenLabel
        margin="none"
      />
      <div>{/* Gap Spacer */}</div>
      <Button size="small" onClick={add}>
        <AddIcon fontSize="small" />
      </Button>
    </div>
  );
};
