import { useEffect, useReducer, useState } from 'react';
import { Permission, useAuthContext } from '../components/authContext';
import {
  cloneLocationConfiguration,
  getConfigurationByLocation,
  getLocations,
  updateLocationConfiguration,
} from '../services/api';
import { Configuration } from '../services/lists';
import { GetUserFunction } from './useAuth';

export enum AdminActionType {
  UpdateLocation = 'UpdateLocation',
  CloneLocation = 'CloneLocation',
  EnableServiceTypes = 'EnableServiceTypes',
}

export type ApiRequest = {
  [AdminActionType.UpdateLocation]: Configuration;
  [AdminActionType.EnableServiceTypes]: Configuration;
  [AdminActionType.CloneLocation]: {
    sourceLocationName: string;
    destinationLocationName: string;
  };
};

type ApiPromiseType = (
  getUser: GetUserFunction,
  request: ApiRequest[AdminActionType]
) => Promise<void>;

export type ExternalState = {
  adminActionType?: AdminActionType;
  location?: string;
  locations?: string[];
  config?: Configuration;
};
type InternalState = {
  apiRequest?: ApiRequest[AdminActionType];
};

type ExternalAction =
  | { type: 'chooseLocation'; location: string }
  | { type: 'chooseAdminActionType'; adminActionType: AdminActionType }
  | { type: 'clearApiRequest' }
  | { type: 'clearAllLocations' };
type InternalAction =
  | { type: 'loadConfig'; config: Configuration }
  | { type: 'loadLocations'; locations: string[] }
  | {
      type: 'setUpdateLocationApiRequest';
      apiRequest: ApiRequest[AdminActionType.UpdateLocation];
    }
  | {
      type: 'setCloneLocationApiRequest';
      apiRequest: ApiRequest[AdminActionType.CloneLocation];
    }
  | {
      type: 'setEnableServiceTypesApiRequest';
      apiRequest: ApiRequest[AdminActionType.EnableServiceTypes];
    };

type AdminFormState = ExternalState & InternalState;
type AdminFormAction = ExternalAction | InternalAction;

export type AdministrateConfigForm = {
  status: {
    loading: boolean;
    error: boolean;
    submitted: boolean;
    submitting: boolean;
  };
  formState: ExternalState;
  canDisplaySubForm: boolean;
  canSubmit: boolean;

  chooseAdminActionType: (type: AdminActionType) => void;
  chooseLocation: (location: string) => void;
  setApiRequest: <T extends AdminActionType>(
    type: T,
    request: ApiRequest[T]
  ) => void;
  clearApiRequest: () => void;
  submit: () => void;
};

const useAdministrateConfigForm = (): AdministrateConfigForm => {
  const { getUser, userCan, loggedIn } = useAuthContext();

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false);

  const [state, dispatch] = useReducer(
    (state: AdminFormState, action: AdminFormAction) => {
      switch (action.type) {
        case 'chooseAdminActionType':
          return {
            ...state,
            location: undefined,
            config: undefined,
            apiRequest: undefined,
            adminActionType: action.adminActionType,
          };
        case 'chooseLocation':
          return {
            ...state,
            config: undefined,
            apiRequest: undefined,
            location: action.location,
          };
        case 'loadConfig':
          return { ...state, config: action.config };
        case 'loadLocations':
          return { ...state, locations: action.locations };
        case 'setUpdateLocationApiRequest':
        case 'setCloneLocationApiRequest':
        case 'setEnableServiceTypesApiRequest':
          return { ...state, apiRequest: action.apiRequest };
        case 'clearApiRequest':
          return { ...state, apiRequest: undefined };
        case 'clearAllLocations':
          return {
            ...state,
            locations: undefined,
            location: undefined,
            config: undefined,
            apiRequest: undefined,
          };
        default:
          return state;
      }
    },
    {}
  );

  useEffect(() => {
    if (loggedIn && !userCan(Permission.AdministrateConsole)) {
      throw new Error('Not authorised');
    }
  }, [userCan, loggedIn]);

  useEffect(() => {
    if (!state.locations) {
      getLocations(getUser).then(locations => {
        setLoading(false);
        dispatch({ type: 'loadLocations', locations });
      });
    }
  }, [state.location]);

  useEffect(() => {
    if (state.location) {
      getConfigurationByLocation(getUser, state.location).then(
        configuration => {
          dispatch({ type: 'loadConfig', config: configuration });
        }
      );
    }
  }, [state.location, getUser, getConfigurationByLocation, dispatch]);

  const chooseAdminActionType = (type: AdminActionType): void => {
    dispatch({ type: 'chooseAdminActionType', adminActionType: type });
  };
  const chooseLocation = (location: string): void => {
    dispatch({ type: 'chooseLocation', location });
  };
  const setApiRequest = <T extends AdminActionType>(
    type: T,
    request: ApiRequest[T]
  ): void => {
    switch (type) {
      case AdminActionType.UpdateLocation:
        dispatch({
          type: 'setUpdateLocationApiRequest',
          apiRequest: request as ApiRequest[AdminActionType.UpdateLocation],
        });
        break;
      case AdminActionType.CloneLocation:
        dispatch({
          type: 'setCloneLocationApiRequest',
          apiRequest: request as ApiRequest[AdminActionType.CloneLocation],
        });
        break;
      case AdminActionType.EnableServiceTypes:
        dispatch({
          type: 'setEnableServiceTypesApiRequest',
          apiRequest: request as ApiRequest[AdminActionType.EnableServiceTypes],
        });
        break;
    }
  };
  const clearApiRequest = (): void => {
    dispatch({ type: 'clearApiRequest' });
  };
  const clearAllLocations = (): void => {
    setLoading(true);
    dispatch({ type: 'clearAllLocations' });
  };

  const submit = (): void => {
    let apiPromise: ApiPromiseType;
    switch (state.adminActionType) {
      case AdminActionType.UpdateLocation:
      case AdminActionType.EnableServiceTypes:
        apiPromise = updateLocationConfiguration as ApiPromiseType;
        break;
      case AdminActionType.CloneLocation:
        apiPromise = cloneLocationConfiguration as ApiPromiseType;
        break;
      default:
        throw new Error('unknown value for AdminActionType');
    }

    if (state.apiRequest) {
      setSubmitting(true);
      apiPromise(getUser, state.apiRequest)
        .then(() => {
          setSubmitting(false);
          setSubmitted(true);
          clearAllLocations();
        })
        .catch(err => {
          setSubmitting(false);
          setError(err?.response?.data || false);
        })
        .finally(() => {
          clearApiRequest();
        });
    }
  };

  useEffect(() => {
    if (error || submitted) {
      setTimeout(() => {
        setError(false);
        setSubmitted(false);
      }, 5000);
    }
  }, [error, submitted]);

  return {
    status: {
      loading,
      error,
      submitted,
      submitting,
    },
    canDisplaySubForm:
      !!state.adminActionType &&
      !!state.locations &&
      !!state.location &&
      !!state.config,
    canSubmit: !!state.apiRequest,
    formState: state,
    chooseAdminActionType,
    chooseLocation,
    setApiRequest,
    clearApiRequest,
    submit,
  };
};
export default useAdministrateConfigForm;
