import { Configuration, FormType, FormTypeValues } from './lists';

/**
 * Returns list of service types that are available for a given location based on its configuration
 * The 'Home' FormType is used to store the available service types in the 'services' property
 * Note: when it is empty, assume that all of the service types are available for the Location
 * @param configuration
 */
export const getServiceTypes = (configuration: Configuration): FormType[] => {
  const serviceTypes = (configuration.items.Home.services as FormType[]) || [];
  return serviceTypes.length === 0 ? FormTypeValues : serviceTypes;
};
