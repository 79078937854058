import { makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { AddListItem } from './addListItem';
import EditableListOption from './editableListOption';

const useStyles = makeStyles({
  marginTop: {
    marginTop: '1.5rem',
  },
});

export interface EditableListProps {
  listKey: string;
  list: string[];
  updateList: (newList: string[]) => void;
  label: string;
}

export const EditableList: React.FC<EditableListProps> = ({
  list,
  listKey,
  updateList,
  label,
}) => {
  const [newItem, setNewItem] = useState<string>('');
  const classes = useStyles();

  const items = list
    .filter(x => x !== 'Other')
    .map((value, index) => (
      <EditableListOption
        key={`${listKey}-${index}`}
        value={value}
        moveUp={(): void => {
          if (index === 0) {
            return;
          }
          const newList = [...list];
          newList[index] = newList[index - 1];
          newList[index - 1] = value;
          updateList(newList);
        }}
        moveDown={(): void => {
          if (index === list.length - 1) {
            return;
          }
          const newList = [...list];
          newList[index] = newList[index + 1];
          newList[index + 1] = value;
          updateList(newList);
        }}
        remove={(): void => {
          const newList = [...list];
          newList.splice(index, 1);
          updateList(newList);
        }}
        onChange={(newValue: string): void => {
          const newList = [...list];
          newList[index] = newValue;
          updateList(newList);
        }}
      />
    ));
  return (
    <>
      <Typography className={classes.marginTop} variant="h6" component="h2">
        {label}
      </Typography>
      <>{items}</>
      <AddListItem
        value={newItem}
        onChange={setNewItem}
        add={(): void => {
          updateList([...list, newItem]);
          setNewItem('');
        }}
      />
    </>
  );
};

export default EditableList;
